$controls-horiz-padding: 20px;
$controls-vert-padding: 10px;


@import "./Spinner";


* {
    margin: 0;
    padding: 0;
}

body {
    overflow: hidden; /* prevent scrolling */
}

body, input, button, label {
    color: rgba(#000, 0.8);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

a {
    cursor: pointer;
    color: inherit;

    &:hover {
        text-decoration: underline;
    }
}

.Logo {
    display: inline-block;
    width: 115px;
    height: 13px;
    background-image: url(../images/logo.svg);
    background-repeat: no-repeat;
    text-indent: -9999em;
    overflow: hidden;
}

.Logo--corner {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 10;
    opacity: 0.3;
    transition: opacity 0.3s ease-out;

    &:hover {
        opacity: 1;
    }
}

.App {
    position: relative;
    overflow: hidden;
    height: 100%;
    min-height: 100vh;
}


.App-controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
}

.App-canvas {
    float: right;
    canvas {
        display: block;
    }
}


.Polyshaper {
    background: #FFF;
    overflow: hidden;
}




.Controls {
    $bg-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    user-select: none;
    background-color: $bg-color;
    overflow: auto; //allow scrolling
    height: 100vh;

    &-header {
        flex: 0 0 auto;
    }

    &-body {
        flex: 1 0 0;
        overflow: auto;
        background-image: 
            /* Shadows */ 
            linear-gradient(to bottom, $bg-color, $bg-color),
            linear-gradient(to bottom, $bg-color, $bg-color),
        
            /* Shadow covers */ 
            linear-gradient(to bottom, rgba(0,0,0,.08), rgba(255,255,255,0)),
            linear-gradient(to top, rgba(0,0,0,.08), rgba(255,255,255,0));   
    
        background-position: center top, center bottom, center top, center bottom;
        background-repeat: no-repeat;
        background-color: $bg-color;
        background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
        
        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }

    &-footer {
        flex: 0 0 auto;
    }
}


.HelpButton {
    outline: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    border: 1px solid rgba(#333, 0.2);
    border-radius: 3px;
    background-color: #FFF;
    color: #333;
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
    opacity: 0.4;
    transition: opacity 0.2s easeOutQuart;

    &:hover {
        opacity: 1;
    }
}



.Field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $controls-vert-padding $controls-horiz-padding;

    > label {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
    }

    input[type=range] {
        max-width: 120px;
    }
}

.Field--choices {
    display: block;
    > label {
        display: block;
    }

    > ul {
        padding-bottom: 10px;
        padding-top: 5px;
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input, label {
            vertical-align: middle;
        }

        label {
            font-size: 11px;
            font-weight: bold;
            opacity: 0.7;
        }

        input[type=radio] {
            margin-right: 4px;
        }
    }
}


.Field--donations {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    align-items: center;

    > p {
        font-size: 10px;
    }

    form {
        display: inline-flex;
        margin-right: 10px;
    }
}

.Button {
    font-size: 11px;
    font-weight: bold;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
}

.Button--native {
    padding: 0.3em 0.6em;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
    border: 1px solid rgba(#000, 0.1);
    background-color: #FFF;
    transition: border-color 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;

    &:hover {
        border-color: rgba(#000, 0.2);
        text-decoration: none;
    }

    &.is-active {
        background-color: #4A90E2;
        color: #FFF;
    }
}

.Button--tool {
    padding: 0;
    background-color: #FFF;

    .Ico {
        width: 25px;
        height: 24px;
    }

    &.is-active {
        background-color: #4A90E2;
        color: #FFF;
    }
}


%Button--primary, .Button--primary {
    line-height: 40px;
    min-width: 120px;
    background-color: #4A90E2;
    border: 1px solid #4A90E2;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    padding: 0 1.4em;
}

.Button--danger {
    @extend %Button--primary;
    background-color: #EC5A6C;
    border: 1px solid #EC5A6C;
    color: #FFF;
}


.Group-label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // vertical-align: middle;
    border-top: 1px solid rgba(#000, 0.05);
    // margin-top: $controls-vert-padding;
    padding: $controls-vert-padding * 2 $controls-horiz-padding $controls-vert-padding;
    font-size: 14px;

    .Field + & {
        margin-top: $controls-vert-padding;
    }
}

.Group-actions {
    font-size: 10px;
    font-weight: normal;
    margin-top: 0;
    vertical-align: middle;

    a {
        color: #4A90E2;
    }

    p {
        display: inline;
        font-style: italic;
        font-size: 10px;
        opacity: 0.8;
    }
}

.MouseTools,
.PointExtractors {
    margin: $controls-vert-padding $controls-horiz-padding;

    button {
        margin-right: 5px;
    }

    .Field {
        margin-left: 0;
        margin-right: 0;
        padding: $controls-vert-padding 0 0 0;
    }
}



.Cursor--brush,
.Cursor--point,
.Cursor--eraser {
    cursor: crosshair;
}

.Cursor--pan {
    cursor: move;
}





.App-dropZone {
    width: 100vw;
    height: 100vh;
}


.Overlay {
    box-sizing: border-box;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #FFF;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: background-color 0.6s easeOutQuart,
                opacity 0.6s easeOutQuart,
                transform 0.6s easeOutQuart;
    transform: scale(1);
}

.Overlay.is-hidden {
    opacity: 0;
    transform: scale(0.98);
    pointer-events: none;
}

.Overlay-image {
    display: block;
    text-align: center;
    margin-bottom: 55px;
}

.Overlay-title {
  color: #4A4A4A;
  margin-bottom: 0.3em;
}

.Overlay-subtitle {
  font-size: 1.2em;
}

.Overlay-title,
.Overlay-subtitle {
    text-align: center;
}

.Overlay-demoImages {
    .App-dropZone.is-active & {
        display: none;
    }
}

.Overlay--dropImage {
    box-sizing: content-box;
    // pointer-events: none;
    cursor: pointer;
    user-select: none;
    border: 50px solid #5996DB;
    left: -50px;
    top: -50px;

    .App-dropZone.is-active & {
        opacity: 1;
        pointer-events: all;
        transform: scale(0.98);
    }
}


.Overlay--trialExpired {
    .Overlay-subtitle {
        margin-bottom: 40px;
    }
}


.DemoImages {
    text-align: center;
    margin: 60px 0;

    &-title {
        margin-bottom: 30px;
        font-size: 1.2em;
        // font-weight: normal;
        // color: #4A4A4A;
    }

    ul {
        list-style: none;
    }

    li {
        display: inline-block;
        position: relative;
        z-index: 1;
        padding: 0 4px;
        transition: 0.2s transform ease-in ;
        transform: scale(1);
    
        &:hover {
            transition:  0.3s transform ease-out;
            z-index: 2;
            transform: scale(1.1);
        }
    }

    img {
        display: block;
    }
}

















.Ico {
    display: inline-block;
}

.Ico-select {
    background-image: url(../images/icons/select.svg);
    &.Ico--cWhite {
        background-image: url(../images/icons/select-white.svg);
    }
}

.Ico-pan {
    background-image: url(../images/icons/pan.svg);
    &.Ico--cWhite {
        background-image: url(../images/icons/pan-white.svg);
    }
}

.Ico-point {
    background-image: url(../images/icons/point.svg);
    &.Ico--cWhite {
        background-image: url(../images/icons/point-white.svg);
    }
}

.Ico-brush {
    background-image: url(../images/icons/brush.svg);
    &.Ico--cWhite {
        background-image: url(../images/icons/brush-white.svg);
    }
}

.Ico-eraser {
    background-image: url(../images/icons/eraser.svg);
    &.Ico--cWhite {
        background-image: url(../images/icons/eraser-white.svg);
    }
}



.BigIco {
    display: inline-block;
}

.BigIco-pics {
    background-image: url(../images/banner-pics.svg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-position: contain;
    width: 161px;
    height: 128px;
}

.BigIco-license {
    background-image: url(../images/banner-license.svg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-position: contain;
    width: 111px;
    height: 111px;
}










select {
    margin-right: 4px;
}



input[type=range] {
  -webkit-appearance: none;
  margin: 6px 0;
  max-width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  box-sizing: content-box;
  width: 100%;
  height: 3px;
  cursor: pointer;
  background-color: #EAEAEA;
  border-radius: 25px;
}

input[type=range]::-webkit-slider-thumb {
  border: 2px solid darken(#DDD, 10%);
  height: 10px;
  width: 10px;
  border-radius: 7px;
  background: #FFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3px;
  transition: border-color 0.2s ease;
}

input[type=range]:focus::-webkit-slider-thumb {
    border-color: #4A90E2;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  // background: #ac51b5;
}




.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0 auto;
    background: #FFF;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    padding: 60px 40px;
    width: 600px;
    box-sizing: border-box;
    // min-height: 80vh;

    .Form {
        margin: 0 auto;
    }
}

.Modal-close {
    font-size: 14px;
    position: absolute;
    top: 5px;
    right: 10px;
    opacity: 0.3;
    cursor: pointer;
    padding: 10px;

    &:hover {
        opacity: 0.5;
    }
}

.Modal-title {
    text-align: center;
    margin-bottom: 1.4em;
}


.FField {
    margin-bottom: 20px;
}

.FField input[type=text],
.FField textarea {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #CECECE;
    background-color: #FFF;
    // font-weight: bold;
    font-size: 16px;
    outline: none;
    border-radius: 4px;
    padding: 1em 1em;
    color: rgba(#4E4E4E, 0.8);
    transition: border-color 0.3s easeOutQuart;

    &:focus {
        border-color: #4A90E2;
    }

    &::-webkit-input-placeholder {
        color: rgba(#4E4E4E, 0.4);
    }
}

.FField input[type=text] {
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
}

.FField textarea {
    resize: vertical;
}




.Form {
    max-width: 460px;
}

.Form-title {
    margin-bottom: 40px;
    color: #4A4A4A;
}

.Form-actions {
    text-align: right;
}

.Form-error {
    margin-top: 20px;
    background-color: #FFCECE;
    border-radius: 4px;
    border: 1px solid #FA8C8C;
    color: #DC5050;
    padding: 1em;
    text-align: left;
}


.Form--license {
    max-width: 100%;
    text-align: center;

    input, button {
        display: inline-block;
        vertical-align: middle;
    }

    input {
        width: 328px!important;
    }

    button {
        width: 120px!important;
        margin-left: 20px;
    }
}


.SuccessView {
    padding: 20px;
    text-align: center;
}



.VersionUpdate-action {
    margin-top: 50px;
    overflow: hidden;
    text-align: center;

    .Button {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
}


.Links {
    a {
        display: block;
        padding: 8px 16px;
        line-height: 20px;
        outline: none;
        cursor: pointer;
        -webkit-appearance: none;
        background-color: transparent;
        border: none;
        color: rgba(#333, 0.6);
        text-decoration: none;
        border-bottom: 1px solid rgba(#333, 0.05);

        transition: color 0.2s easeOutQuart, background-color 0.2s easeOutQuart;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            text-decoration: underline;
            color: #4A90E2;
            background-color: rgba(#333, 0.01);
        }
    }
}





.Shortcuts {
    display: flex;
    flex-wrap: wrap;

    > div {
        width: 50%;
        margin-bottom: 2em;
    }

    ul {
        list-style: none;
    }

    h2 {
        margin-bottom: 1em;
        font-size: 22px;
    }
}

.Shortcut {
    line-height: 1.3em;
    margin-bottom: 0.4em;
    display: flex;
    align-items: baseline;
}

.Shortcut-label {
    font-size: 15px;
    color: #333;

    span {
        font-weight: normal;
        text-transform: capitalize;
    }
}

.Shortcut-cmd {
    font-family: monospace;
    display: inline-block;
    padding: 3px 6px;
    text-align: center;
    min-width: 14px;
    background-color: #EEE;
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: bold;
    color: #333;
    margin-right: 10px;
}






.ReactModalPortal {
    z-index: 99999;
    position: absolute;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms easeOutQuart;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Content {
    opacity: 0;
    transform: scale(0.98) translateY(-5px);
}

.ReactModal__Content--after-open {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: opacity 500ms easeOutQuart, transform 500ms easeOutQuart;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content--before-close {
    opacity: 0;
    transform: scale(0.98) translateY(5px);
    transition: opacity 500ms easeOutQuart, transform 500ms easeOutQuart;
}















.Dropdown {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .group > .title{
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1.2);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
   border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}










$font-size-base         : 12px;
$line-height-base       : 1.5;
$border-radius-base     : 6px;
$overlay-shadow              : 0 0 4px rgba(0, 0, 0, 0.17);
// Tooltip text color
$tooltip-color: #333;
// Tooltip background color
$tooltip-bg: #FFF;
$tooltip-opacity: 1;

// Tooltip arrow width
$tooltip-arrow-width: 5px;
// Tooltip distance with trigger
$tooltip-distance: $tooltip-arrow-width + 4;
// Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

// Base class
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  // remove left/top by yiminghe
  // left: -9999px;
  // top: -9999px;
  font-size: $font-size-base;
  line-height: $line-height-base;
  opacity: $tooltip-opacity;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));

  &-hidden {
    display: none;
  }

  &-placement-top, &-placement-topLeft, &-placement-topRight {
    padding: $tooltip-arrow-width 0 $tooltip-distance 0;
  }
  &-placement-right, &-placement-rightTop, &-placement-rightBottom {
    padding: 0 $tooltip-arrow-width 0 $tooltip-distance;
  }
  &-placement-bottom, &-placement-bottomLeft, &-placement-bottomRight {
    padding: $tooltip-distance 0 $tooltip-arrow-width 0;
  }
  &-placement-left, &-placement-leftTop, &-placement-leftBottom {
    padding: 0 $tooltip-distance 0 $tooltip-arrow-width;
  }
}

// Wrapper for the tooltip content
.rc-tooltip-inner {
  // padding: 8px 10px;
  color: $tooltip-color;
  text-align: left;
  text-decoration: none;
  background-color: $tooltip-bg;
  border-radius: $border-radius-base;
  box-shadow: $overlay-shadow;
  // min-height: 34px;
}

// Arrows
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-tooltip {
  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow {
    bottom: $tooltip-distance - $tooltip-arrow-width;
    margin-left: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }

  &-placement-top &-arrow {
    left: 50%;
  }

  &-placement-topLeft &-arrow {
    left: 7px;
  }

  &-placement-topRight &-arrow {
    right: 7px;
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    left: $tooltip-distance - $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
    border-right-color: $tooltip-arrow-color;
  }

  &-placement-right &-arrow {
    top: 50%;
  }

  &-placement-rightTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-rightBottom &-arrow {
    bottom: 15%;
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    right: $tooltip-distance - $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-left-color: $tooltip-arrow-color;
  }

  &-placement-left &-arrow {
    top: 50%;
  }

  &-placement-leftTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-leftBottom &-arrow {
    bottom: 15%;
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    top: $tooltip-distance - $tooltip-arrow-width;
    margin-left: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }

  &-placement-bottom &-arrow {
    left: 50%;
  }

  &-placement-bottomLeft &-arrow {
    left: 15%;
  }

  &-placement-bottomRight &-arrow {
    right: 15%;
  }
}