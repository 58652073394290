$spinner-size: 14px;
$spinner-fg-color: #4A90E2;
$spinner-bg-color: rgba($spinner-fg-color, 0.15);
$spinner-ring-width: 0.2em;


.Spinner {
  position: absolute;
  z-index: 99999999999;
  top: 10px;
  right: 10px;
  text-indent: -9999em;
  border-top: $spinner-ring-width solid $spinner-bg-color;
  border-right: $spinner-ring-width solid $spinner-bg-color;
  border-bottom: $spinner-ring-width solid $spinner-bg-color;
  border-left: $spinner-ring-width solid $spinner-fg-color;
  transform: translateZ(0);
  animation: spin 0.8s infinite linear;
  animation-play-state: paused;
  opacity: 0;

  &.is-active {
    animation-play-state: running;
    opacity: 1;
  }
}

.Spinner,
.Spinner:after {
  font-size: $spinner-size;
  border-radius: 50%;
  width: 1em;
  height: 1em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
